import React from "react";
import playArrow from "../images/play-youtube-logo.png";
import classNames from "classnames";

interface Speaker {
    name: string,
    photo: string,
    description: string | React.ReactNode | React.ReactNode[],
    youtubeLink?: string,
    youtubeName?: string,
    sex?: 'm' | 'f',
}

export interface IConferenceEditionProps {
    containerId: string
    className?: string,
    title: string,
    subtitle: string,
    description: string | React.ReactNode | React.ReactNode[],
    ticketsUrl: string,
    speakers: Speaker[],
}

const PlayButton: React.FunctionComponent<{speaker: Speaker}> = ({speaker}) => {
    return <div className={"play d-flex justify-content-center"}>
        <a className={"play-button mt-5"} href={speaker?.youtubeLink || "https://www.youtube.com/@fainsisimplu/videos"}
           target={"_blank"}>
            <div className={"play-arrow d-flex"}>
                <img src={playArrow} alt={"Play"} className={"align-self-center"}/>
            </div>
            <div className={"play-text d-flex"}>
                <div className={"align-self-center px-3"}>
                    {
                        speaker?.youtubeLink
                            ? <>
                                Urmărește episodul F&S<br/>
                                cu {speaker.youtubeName}
                            </>
                            : "Urmărește aici toate episoadele F&S"
                    }

                </div>
            </div>
        </a>
    </div>
}

const ConferenceEdition: React.FunctionComponent<IConferenceEditionProps> = ({
                                                                                 containerId,
                                                                                 className,
                                                                                 title,
                                                                                 subtitle,
                                                                                 description,
                                                                                 ticketsUrl,
                                                                                 speakers
                                                                             }) => {
    const classes = className || "container-fluid conference-edition py-5";

    return <div className={classes} id={containerId}>
        <div className={"container"}>
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-smxl-6 mb-4"}>
                    <h2 className={"font-caxton fs-1 text-center"}>{title}</h2>
                </div>
            </div>
            <div className={"row justify-content-center mb-2"}>
                <div className={"col-12 col-smxl-8"}>
                    <h3 className={"fw-bold fs-4 text-center"}>{subtitle}</h3>
                </div>
            </div>
            <div className={"row justify-content-center mb-3"}>
                <div className={"col-12"}>
                    {
                        "string" === typeof description
                            ? <p className={"text-justify "}>{description}</p>
                            : description
                    }
                </div>
            </div>

            <div className={"row justify-content-center"}>
                {
                    speakers.map((speaker, index) => {
                        const containerClasses = classNames({
                            "col-12": true,
                            "col-md-6": speakers.length > 1,
                            "pe-lg-5": speakers.length > 1 && index %2 === 0,
                            "ps-lg-5": speakers.length > 1 && index %2 === 1,
                            "mb-5": true,
                        });

                        const speakerImageColClasses = classNames({
                            "col-12": true,
                            "col-md-6": speakers.length === 1,
                            "align-self-center": true
                        });

                        const colClasses = classNames({
                            "col-md-6": speakers.length === 1,
                            "col-12": speakers.length > 1,
                            "align-self-center": speakers.length === 1
                        });


                        return <div className={containerClasses} key={index}>
                            <div className={"d-flex flex-column h-100"}>
                                <div className={"row"}>
                                    <div className={colClasses}>
                                        <div className={"img text-center"}>
                                            <img src={speaker.photo} alt={speaker.name} className={"speaker-photo"}/>
                                        </div>
                                    </div>

                                    <div className={colClasses}>
                                        <div className={"description flex-grow-1"}>
                                            <h3 className={`fw-normal fs-4 ${speakers.length === 1 ? "text-center text-md-start": "text-center"} font-caxton my-3`}>{speaker.name}</h3>
                                            {
                                                "string" === typeof speaker.description
                                                    ? <p className={"mb-0"} style={{textAlign: "justify"}}>{speaker.description}</p>
                                                    : speaker.description
                                            }
                                        </div>
                                        {/*<div className={"d-smxl-none"}>*/}
                                        <div>
                                            <PlayButton speaker={speaker} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            {/*<div className={"row d-none d-smxl-flex"}>*/}
            {/*    {speakers.map((speaker, index) => <div className={`col ${index === 0 ? "pe-lg-5" : "ps-lg-5"}`} key={index}>*/}
            {/*        <PlayButton speaker={speaker} />*/}
            {/*    </div>)}*/}
            {/*</div>*/}
        </div>
    </div> ;
}

export default ConferenceEdition;