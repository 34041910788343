import React from "react";
import heroMobile from "../images/hero-mobile.jpg";

import lidl from "../images/sponsors/lidl.jpg";
import crama from "../images/sponsors/crama-de-matei.jpg";
import boost from "../images/sponsors/boost4life.jpg";

const Header: React.FunctionComponent = () => {
    return <>
        <div className={"header"}>
            <div className={"d-smxl-none container-fluid px-0"}>
                <div className={"mobile-hero"}>
                    <img src={heroMobile} alt={"Fain & Simplu"} className={"w-100"}/>
                </div>
            </div>

            <div className={"d-none d-smxl-block moto-container"}>
                <div className={"container-smxl-fluid container-xl"}>
                    <div className={"row"}>
                        <div className={"col-6 text-start text-uppercase fw-bold px-0 ps-3"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    Despre a trăi fain, dar a rămâne simplu
                                </div>
                                <div className={"col-12 pt-2"}>
                                    <div className={"row justify-content-start"}>
                                        <div className={"col-auto"}>
                                            <img src={boost} height={55}/>
                                        </div>
                                        <div className={"col-auto"}>
                                            <img src={lidl} height={55}/>
                                        </div>
                                        <div className={"col-auto"}>
                                            <img src={crama} height={55}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-6 text-end text-uppercase fw-bold px-0 pe-3"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    Club Pentru minte, trup și suflet.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"badge-container"}>
                <div className={"container-fluid container-smxl"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-auto"}>
                            <div className={"badge"}>
                                <h1 className={"m-0 text-center"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-auto"}>
                                            <div className={"row justify-content-center"}>
                                                <div className={"col-auto align-self-center"}>
                                                    <a target={"_blank"} href={"https://tickets.fainsisimplu.ro/"}
                                                       className={"d-block d-sm-inline"}><b>F&S x Ateneu</b>, 12
                                                        Octombrie</a>
                                                </div>
                                                <div className={"flex-grow-1 flex-sm-grow-0 col-sm-auto"}>
                                                    <span className={"d-none d-sm-inline"}>|</span>
                                                </div>
                                                <div className={"col-auto"}>
                                                    <a target={"_blank"} href={"https://tickets.fainsisimplu.ro/"}
                                                       className={"d-block d-sm-inline"}><b>Fain & Simplu x Brașov</b>,
                                                        29
                                                        septembrie</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"col-12 pt-4 d-smxl-none"}>
            <div className={"row justify-content-center"}>
                <div className={"col-auto"}>
                    <img src={boost} height={55}/>
                </div>
                <div className={"col-auto"}>
                    <img src={lidl} height={55}/>
                </div>
                <div className={"col-auto"}>
                    <img src={crama} height={55}/>
                </div>
            </div>
        </div>
    </>
}

export default Header;