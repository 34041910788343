import React from "react";
import chisinau from "../images/conferences/fain-si-simplu-chisinau.jpg";
import ateneu from "../images/conferences/fain-si-simplu-ateneu.jpg";
import timisoara from "../images/conferences/fain-si-simplu-timisoara.jpg";
import inCurand from "../images/conferences/in-curand.jpg";
import cluj from "../images/conferences/fain-si-simplu-cluj.jpg";
import classNames from "classnames";

interface IConferenceThumb {
    name: string,
    thumb: string,
    ticketsUrl?: string,
    available?: boolean,
    scrollTo?: string,
    inPast?: boolean,
    inPastText?: string,
}

const Conference: React.FunctionComponent<IConferenceThumb> = ({name, thumb, ticketsUrl, scrollTo, available = false, inPast= false, inPastText = ""}) => {
    const btnClasses = classNames({
        "btn-buy": true,
        "disabled": !available
    })

    const detailsLinkClasses = classNames({
        "details-link": true,
        "disabled": !available
    })

    const imageClasses = classNames({
        "image": true,
        "in-past": inPast
    });

    return <div className={"col-6 col-md-4 conference d-flex flex-column"}>
        <div className={"d-flex flex-grow-1 flex-column"}>
            <div className={imageClasses}>
                <a
                    {
                        ...(available ? {
                            style: {cursor: "pointer"},
                            title: `${name} - Vezi detalii`,
                            onClick: () => document.getElementById(scrollTo as string)?.scrollIntoView({behavior: "smooth"})
                        } : {})
                    }
                >
                    <img src={thumb} alt={name} className={"mw-100 w-100"} />
                </a>

                {
                    inPast
                        ? <div className={"past-conference-text-container flex-column justify-content-center"}>
                            <div className={"align-self-center d-flex past-conference-text"}>
                                {inPastText}
                            </div>
                        </div>
                        : []
                }

            </div>

            <div className={"title align-self-center align-self-center flex-grow-1 d-flex"}>
                <div className={"container px-0"}>
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            <a
                                className={btnClasses}
                                {
                                    ...(available ? {
                                        href: ticketsUrl,
                                        target: "_blank",
                                        title: `${name} - Cumpără bilete`
                                    } : {})
                                }
                            >
                                Cumpară Bilete
                            </a>
                        </div>
                    </div>

                    {
                        available
                            ? <div className={"row mt-2"}>
                                <div className={"col-12 text-center"}>
                                    <a
                                        className={detailsLinkClasses}
                                        {
                                            ...(available ? {
                                                title: `${name} - Vezi detalii`,
                                                onClick: () => document.getElementById(scrollTo as string)?.scrollIntoView({behavior: "smooth"})
                                            } : {})
                                        }
                                    >
                                        Vezi detalii
                                    </a>
                                </div>
                            </div>
                            : []

                    }

                </div>
            </div>
        </div>
    </div>
}

const Conferences: React.FunctionComponent = () => {
    const speakers: IConferenceThumb[] = [
        {
            name: "Fain & Simplu x Chișinău",
            ticketsUrl: "https://livetickets.md/ro/event/11--fain-and-simplu-x-chisinau",
            thumb: chisinau,
            available: false,
            scrollTo: "chisinau-edition",
            inPast: true,
            inPastText: "7 octombrie"
        },
        {
            name: "Fain & Simplu x Ateneu",
            ticketsUrl: "https://tickets.fainsisimplu.ro/",
            thumb: ateneu,
            available: false,
            scrollTo: "ateneu-edition",
            inPast: true,
            inPastText: "14 octombrie"
        },
        {
            name: "Fain & Simplu - în curând",
            thumb: cluj,
            scrollTo: "cluj-edition",
            available: true,
            ticketsUrl: "https://tickets.fainsisimplu.ro/",

        },
        {
            name: "Fain & Simplu x Timișoara",
            // ticketsUrl: "https://tickets.fainsisimplu.ro/",
            thumb: timisoara,
            available: false,
            scrollTo: "timisoara-edition",
            inPast: true,
            inPastText: "Sold Out"
        }

    ]

    return <div className={"container conferences my-5"}>
        <div className={"row"}>
            <div className={"col-12 text-center"}>
                <h1 className={"font-caxton fs-2 fw-normal"}>Conferințele Fain & Simplu</h1>
            </div>
        </div>
        <div className={"row justify-content-center my-4"}>
            <div className={"col-12 col-smxl-6"}>
                <p className={"text-center"}>
                    <b>Fain & Simplu</b> depășește granițele lumii virtuale și te invită să te bucuri de o altfel de cunoaștere. Cunoaștere care, pe lângă noi resurse spirituale, intelectuale, îți aduce povești și experiențe care conectează. Într-o lume tot mai lipsită de sens, dialogurile Fain & Simplu redau importanța echilibrului dintre minte, trup și suflet.
                </p>
            </div>
        </div>
        {/*<div className={"row"}>*/}
        {/*    <div className={"col-12 text-center"}>*/}
        {/*        <h2 className={"font-caxton fs-4 fw-normal mb-3"}>Următoarele conferințe</h2>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className={"row justify-content-lg-center"}>*/}
        {/*    {*/}
        {/*        speakers.map((speaker, index) => <Conference key={index} {...speaker} />)*/}
        {/*    }*/}
        {/*</div>*/}
    </div>
}

export default Conferences;