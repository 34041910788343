import React from "react";
import BuyButton from "./buy_button";

export interface IScheduleEntry {
    time: string,
    description: string
}

const ScheduleEntry: React.FunctionComponent<IScheduleEntry> = ({time, description}) => {
    return <div className={"row schedule-entry"}>
        <div className={"col col-lg-6 time text-right text-end pt-2 pb-2"}>
            {time}
        </div>
        <div className={"col col-lg-4 description pt-2 pb-2"}>
            {description}
        </div>
    </div>
}

interface ISchedule {
    title?: string,
    entries: IScheduleEntry[],
    ticketsAvailable?: boolean
}
const Schedule: React.FunctionComponent<ISchedule> = ({title, entries, ticketsAvailable = true}) => {
    return <div className={"schedule position-relative"}>
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h2 className={"font-caxton"}>
                        { title ?? 'Agendă' }
                    </h2>
                </div>
            </div>
        </div>
        <div className={"container schedule-entries mt-4 pb-4"}>
            {
                entries.map((entry, index) => <ScheduleEntry key={index} {...entry} />)
            }
        </div>

        {
            ticketsAvailable
                ? <div className={"container mt-5"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-auto"}>
                            <BuyButton/>
                        </div>
                    </div>
                </div>
                : []
        }
    </div>
}

export default Schedule