import React from "react";
import sampleArt from "../images/past-conferences/thumb/fs-sample-art.jpg";
import sampleArtBig from "../images/past-conferences/large/sample-art-big.jpg";
import artThumb1 from '../images/past-conferences/thumb/01.jpg'
import artThumb2 from '../images/past-conferences/thumb/02.jpg'
import artThumb3 from '../images/past-conferences/thumb/03.jpg'
import artThumb4 from '../images/past-conferences/thumb/04.jpg'
import artThumb5 from '../images/past-conferences/thumb/05.jpg'
import artThumb6 from '../images/past-conferences/thumb/06.jpg'
import artThumb7 from '../images/past-conferences/thumb/07.jpg'
import artThumb8 from '../images/past-conferences/thumb/08.jpg'
import art1 from '../images/past-conferences/large/01.jpg'
import art2 from '../images/past-conferences/large/02.jpg'
import art3 from '../images/past-conferences/large/03.jpg'
import art4 from '../images/past-conferences/large/04.jpg'
import art5 from '../images/past-conferences/large/05.jpg'
import art6 from '../images/past-conferences/large/06.jpg'
import art7 from '../images/past-conferences/large/07.jpg'
import art8 from '../images/past-conferences/large/08.jpg'
import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'
import BuyButton from "./buy_button";

const Past_conferences: React.FunctionComponent = () => {
    const images: {thumb: string, big: string, width: number, height: number}[] = [
        {
            thumb: artThumb1,
            big: art1,
            width: 854,
            height: 571
        },
        {
            thumb: artThumb2,
            big: art2,
            width: 1920,
            height: 1280
        },
        {
            thumb: artThumb3,
            big: art3,
            width: 855,
            height: 572
        },
        {
            thumb: artThumb4,
            big: art4,
            width: 858,
            height: 575
        },
        {
            thumb: artThumb5,
            big: art5,
            width: 1920,
            height: 1280
        },
        {
            thumb: artThumb6,
            big: art6,
            width: 855,
            height: 571
        },
        {
            thumb: artThumb7,
            big: art7,
            width: 1920,
            height: 1280
        },
        {
            thumb: artThumb8,
            big: art8,
            width: 1920,
            height: 1280
        },
    ]

    Array(8).fill({
        thumb: sampleArt,
        big: sampleArtBig,
        with: 854,
        height: 571
    })

    return <>
        <div className={"past-conferences position-relative"}>
            <div className={"container content-container"}>
                <div className={"row justify-content-center mb-4"}>
                    <div className={"col col-lg-8"}>
                        <h2 className={"font-caxton fs-2"}>Cum este la conferințele Fain & Simplu</h2>
                    </div>
                </div>

                <div className={"row justify-content-center"}>
                    <div className={"col col-lg-10"}>
                        <p>Într-o societate în care pare că ,,asistăm la moartea clinică a iubirii” (Gellu Naum),
                            comunitatea Fain & Simplu se conectează, îmbrățișează iubirea de oameni, dar și de
                            cunoaștere,
                            în toate formele ei, interacționează și se unește în scopul unui ideal comun: arta de a trăi
                            simplu și frumos.</p>
                    </div>
                </div>

                <Gallery id={"past-conferences"}>
                    <div className={"row"}>
                        {
                            images.map((image, index) => <Item
                                original={image.big}
                                width={image.width}
                                height={image.height}
                                key={index}
                            >
                                {({ref, open}) => (
                                    <div className={"col-6 col-smxl-3 mb-3"}>
                                        <img
                                            style={{cursor: 'pointer'}}
                                            src={image.thumb}
                                            ref={ref as React.MutableRefObject<HTMLImageElement>}
                                            onClick={open}
                                            className={"w-100"}
                                        />
                                    </div>
                                )}
                            </Item>)
                        }

                    </div>
                </Gallery>
            </div>
            <div className={"mix-bg"}/>
        </div>
        <div className={"container"}>
            <div className={"row justify-content-center"}>
                <div className={"col-auto"}>
                    <BuyButton />
                </div>
            </div>
        </div>
    </>;
}

export default Past_conferences;